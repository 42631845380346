<template>
  <dashboard-content full-width>
    <page-header sticky>
      <h1 class="mb-4">
        Users
      </h1>
    </page-header>
    <v-row>
      <v-col class="col-12 py-0">
        <v-select
          v-model="filterValue"
          :items="filterItems"
          label="Filtering by Role"
          name="filter-by-role"
          @change="filterChangeHandler($event)"
        />
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              :append-icon="mdiMagnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            mobile-breakpoint="1140"
            :headers="headers"
            :items="items"
            :search="search"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ formatDatetimeLocal(item.createdAt) }}
            </template>
            <!-- combine first and last name for display -->
            <template v-slot:item.first_name="{ item }">
              {{ item.first_name + ' ' + item.last_name }}
            </template>
            <template v-slot:item.action="{ item }">
              <span class="no-wrap">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item.id)"
                >
                  {{ mdiPencil }}
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  @click="showItem(item.id)"
                >
                  {{ mdiEye }}
                </v-icon>
                <v-icon
                  small
                  @click="removeItem(item.id)"
                >
                  {{ mdiClose }}
                </v-icon>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <confirm-modal
        v-if="selectedItemId"
        :dialog="confirmDialog"
        @agree="remove"
        @disagree="confirmDialog = false"
      />
    </v-row>
  </dashboard-content>
</template>

<script>
import { mdiEye, mdiPlus, mdiPencil, mdiClose, mdiMagnify } from '@mdi/js'
import DashboardContent from '@/components/dashboard/DashboardContent'
import ConfirmModal from '@/components/ConfirmModal'
import PageHeader from '@/components/dashboard/PageHeader.vue'

export default {
  name: 'UserList',
  components: { ConfirmModal, DashboardContent, PageHeader },
  data() {
    return {
      items: [],
      firstItems: {},
      headers: [
        { text: 'Created at', value: 'createdAt', sortable: true },
        { text: 'Email', value: 'email', sortable: true, filterable: true },
        { text: 'Name', value: 'first_name', sortable: true, filterable: true },
        { text: 'Role', value: 'role', sortable: true },
        { text: 'Clients', value: 'countClients', sortable: false },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      mdiEye,
      mdiPlus,
      mdiClose,
      mdiMagnify,
      mdiPencil,
      filters: {},
      filterValue: '',
      filterItems: [
        { text: 'All', value: '' },
        { text: 'Admin', value: 'admin' },
        { text: 'Provider', value: 'provider' }
      ],
      confirmDialog: false,
      selectedItemId: null,
      sortField: 'createdAt',
      search: ''
    }
  },
  
  async created() {
    await this.loadItems()
  },
  methods: {
    filterChangeHandler(value) {
      this.filters =
        value !== ''
          ? {
              ...this.filters,
              ['role']: value
            }
          : {}
      this.loadItems()
    },
    async loadItems() {
      this.loading = true
     const collection =
        this.preparedCollection ||
        await this.$firebase.firestore().collection('users')
      let query = collection.orderBy(this.sortField, 'desc')
      if (
        Object.keys(this.filters).length !== 0 &&
        this.filters.constructor === Object
      ) {
        Object.keys(this.filters).forEach(item => {
          query = query.where(item, '==', this.filters[item])
        })
      }

      return this.$bind('items', query).then(data => {
        const finalData = data.map((obj) => {
          let temp = { ...obj, name: `${obj.firstName} ${obj.lastName}` }
          let res = {}

          for (const [key, value] of Object.entries(temp)) {
            if (key === "firstName" || key === "lastName") continue
            else res[key] = value
          }
          return res
        })
        this.loading = false
        return finalData
      })
    },
    editItem(id) {
      this.$router.push({ name: 'userEdit', params: { id: id } })
    },
    showItem(id) {
      this.$router.push({ name: 'userShow', params: { id } })
    },
    removeItem(id) {
      this.selectedItemId = id
      this.confirmDialog = true
    },
    async remove() {
      await this.$firebase
        .firestore()
        .collection('users')
        .doc(this.selectedItemId)
        .delete()
        .then(() => {
          this.$notify({
            text: 'Successfully deleted'
          })
        })
        .catch(() => {
          this.$notify({
            type: 'err',
            text: 'Error occurred during delete'
          })
        })

      this.confirmDialog = false
    }
  }
}
</script>
<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>